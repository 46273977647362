<!-- 我的优惠券--未使用 -->
<style lang='scss' scoped>

    .browseLesson {
      width: 965px;
      padding: 0 0 20px 0;
      min-height: 900px;
      position: relative;
      font-family: 'PingFangSC-Regular';
    }

    .myclass-title {
        padding: 26px 60px 12px 20px;
        width: 830px;
        font-size: 19px;
        position: relative;
        text-align: left;
    }

    .sanjiao {
        padding: 0 0 0 55px;

    }

    .coupon_count {
        font-size: 12px;
        position: absolute;
        right: 12px;
        color: #fff;
        right: 5px;
    }

    .coupon_name {
        position: absolute;
        margin-left: 5px;
        margin-top: 6px;
        text-align: left;
        font-size: 12px;
        line-height: 24px;
        p{
          margin-bottom: 0px;
        }
    }

    .coupon_name_red {
        color: #ee2e2e;
    }

    .coupon_name_black {
        color: #666;
    }

    .coupon_btn {
        float: right;
        position: relative;
        width: 150px;
        margin-top: 50px;
        margin-right: 20px;
    }

    .period {
        height: 0px;
    }

    .period_title {
        height: 40px;
    }

    .money_text {
        padding-top: 8px;
        width: 148px;
        position: absolute;
        text-align: center;
    }

    .money_text_one {
        font-size: 15px;
        color: #fff;
        text-align: center;
        position: relative;
        z-index: 66;
    }

    .money_text_tow {
        font-size: 30px;
        color: #fff;
        padding-left: 5px;
        position: relative;
        z-index: 66;
    }

    .money_text_three {
        position: relative;
        color: #fff;
        z-index: 66;
        top: -2px;
    }

    .period_text {
        font-size: 14px;
        color: #ee2e2e;
        float: left;
        padding-left: 120px;
        padding-top: 5px;
        position: relative;
        font-weight: 700;
        // line-height: 40px;
        top: -5px;
    }

    .period_text2 {
        font-size: 14px;
        color: #333;
        float: left;
        font-weight: 500;
        padding-left: 20px;
        padding-top: 5px;
        position: relative;
        top: -5px;
    }

    .lesson_name {
        float: left;
        position: relative;
        width: 200px;
        text-align: left;
        font-weight: 500;
        margin-left: 160px;
        color: #333;
        padding-top: 5px;
        font-size: 17px;
        overflow: hidden;
        word-break: break-all; //强行换行,将截断英文单词
        text-overflow: ellipsis; //当元素内文本溢出时（超过width部分）显示省略标记（...）
        white-space: nowrap; //文本不会换行，文本会在在同一行上继续，直到遇到 <br> 标签为止

    }

    .cou_ticket2 {
        height: 91px;
        background: #f7f7f7;
    }

    .coupon_brw {
        display: flex;
        flex-wrap: wrap;
    }

    .coupon_item {
        width: 427px;
        // height: 145px;
        margin-left: 30px;
        margin-bottom: 20px;
        margin-top: 20px;
        position: relative;
        border: 1px solid #dfdfdf;
        border-radius: 11px;
        box-shadow: #dfdfdf 0px 0px 10px 0px;
    }

    .cou_ticket1 {
        position: relative;
        height: 78px;
        width: 430px;
        background: url(https://newoss.zhulong.com/forum/202205/23/11/1653275667342613.png) no-repeat;

    }

    .title-border {
        position: absolute;
        left: 10px;
        bottom: 15px;
        width: 4px;
        height: 20px;
        z-index: 2;
        background: #f04e4f;
    }

    .colect {
        width: 908px;
        height: 165px;
        border-bottom: 1px solid #F1F1F1;
        margin-bottom: 20px;
        padding: 24px 0;
    }

    .colect img {
        width: 157px;
        height: 120px;
        float: left;
    }

    .colect .colect-card-text {
        float: left;
        height: 120px;
        width: 680px;
        margin-left: 20px;
        position: relative;
        color: #333333;
        text-align: left;
    }

    .colect .colect-card-text .title {
        font-size: 16px;
        color: #333333;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        width: 100%;
    }

    .collect-opt {
        width: 90px;
        height: 30px;
        background: #ee2e2e;
        text-align: center;
        line-height: 30px;
        color: #FFFFFF;
        display: inline-block;
        border-radius: 5px;
        cursor: pointer;
    }

    .cancelCollect {
        width: 90px;
        height: 30px;
        color: #ee2e2e;
        border: 1px solid #ee2e2e;
        text-align: center;
        line-height: 30px;
        background-color: #FFFFFF;
        display: inline-block;
        border-radius: 5px;
        cursor: pointer;
        margin-left: 5px;
    }

    .give_friend {
        float: left;
        width: 66px;
        height: 25px;
        border: 1px solid #ee2e2e;
        border-radius: 15px;
        text-align: center;
        line-height: 25px;
        color: #ee2e2e;
        cursor: pointer;
    }

    .go_use {
        float: right;
        width: 66px;
        height: 25px;
        border: 1px solid #ee2e2e;
        border-radius: 15px;
        text-align: center;
        line-height: 25px;
        background: #ee2e2e;
        color: #fff;
        cursor: pointer;
    }

    .clear {
        border: 1px solid #ee2e2e;
        width: 100px;
        border-radius: 15px;
        margin: 0 auto;
        height: 25px;
        line-height: 25px;
        color: #ee2e2e;
        cursor: pointer;
    }

    .page {
        padding: 20px 0;
        margin: 0 auto;
    }

    /deep/ .el-dialog__header {
        padding: 20px 20px 10px;
        text-align: left;
        font-weight: bold;
        background: #F7F7F7;
        border-bottom: 1px solid #CCCCCC;
        border-top: 5px solid #CCCCCC;
        border-right: 5px solid #CCCCCC;
        border-left: 5px solid #CCCCCC;
        /*box-shadow: 2px 2px 32px 0px rgba(193, 193, 193, 0.72);*/

    }

    /deep/ .el-dialog__body {
        height: 320px;
        border-bottom: 5px solid #CCCCCC;
        border-right: 5px solid #CCCCCC;
        border-left: 5px solid #CCCCCC;
    }

    /deep/ .el-dialog__wrapper {
        width: 700px;
        margin: 0 auto;
    }

    .iv_qr_code {
        display: block;
        margin: 0 auto;
        width: 200px;
        height: 200px;
        margin-top: 16px;
    }

    .dialog_tip {
        font-size: 10px;
        color: gray;
        margin-top: 16px;
    }

    .dialog_span {
        display: inline-block;
        margin-left: 4px;
        font-size: 8px;

    }
    .noDataShow{
      position: absolute;
      width: 180px;
      height: 180px;
      top: 50%;
      left: 50%;
      margin-top: -90px;
    }
    .noDataShow img {
      display: block;
      margin: auto;
    }
    .noDataShow p {
      text-align: center;
      font-size: 14px;
      color: #999999;
      margin-top: 30px;
    }
</style>

<template>
  <div class="browseLesson">
    <!-- <p class="myclass-title">
      <span class="title-text">未使用</span>
      <span class="title-border"></span>
    </p> -->
    <!-- 头部 -->
    <headers ref="headers" :headname="headName"></headers>
    <div
      v-show="resultLocalData.result.length>0"
      class="coupon_brw"
    >
      <div v-for="(item,index) in resultLocalData.result" :key="index">
        <div class="coupon_item">
          <div v-if="parseFloat(item.discount_radio)" class="money_text">
            <span class="money_text_tow">{{ item.discount_radio_name }}</span>
            <p class="money_text_three">{{ item.left_name }}</p>
          </div>
          <div v-else class="money_text">
            <span class="money_text_one">¥</span>
            <span v-show="item.money" class="money_text_tow">{{ item.money }}</span>
            <p class="money_text_three">{{ item.left_name }}</p>
          </div>
          <p class="cou_ticket1">
            <span class="lesson_name">{{ item.right_name }}</span>
            <!-- <img src="@/assets/image/sanjiao.png" alt="" class="sanjiao">
            <span class="coupon_count">{{ item.count }}张</span> -->
            <!-- <span v-if="item.expire_hours">
              <span class="period_text">：使用有效期至</span>
              <span class="period_text2">{{ item.end_time }}</span>
            </span>
            <span v-else>
              <span class="period_text">可使用时间</span>
              <span class="period_text2">{{ item.start_time+ '-' + item.end_time }}</span>
            </span> -->
          </p>

          <div class="cou_ticket2">
            <div class="coupon_name">
              <p>
                <span v-if="item.expire_hours" class="coupon_name_black">· 使用有效期至:{{ item.end_time }} </span>
                <span v-else class="coupon_name_black">· 可使用时间：{{ item.start_time+ '-' + item.end_time }} </span>
              </p>
              <!-- <p>
                <span class="coupon_name_black">· </span>
                <span class="coupon_name_red">{{ item.send_username }} </span>
                <span class="coupon_name_black">送给您的课程优惠券 </span>
              </p> -->
              <p v-show="item.type_show">
                <span class="coupon_name_black">· 仅限{{ item.type_name }}使用</span>
              </p>
              <p>
                <span class="coupon_name_black">· 多张课程专享券{{ item.superposition == 0 ? '不能' : '能' }}叠加使用 </span>
              </p>
            </div>

            <div class="coupon_btn">
              <!--定金卷不能赠送-->
              <span v-show="item.type_id != 3" class="give_friend" @click="goToDetails(item.url_qrcode)">送朋友</span>
              <span class="go_use" @click="usercoupon(item)">去使用</span>
            </div>

          </div>
        </div>

      </div>
    </div>

    <noData v-show="isShowEmpty" ref="noData" :msg="msg" :img="img"></noData>
    <!-- <div
      v-show="isShowEmpty"
      class="noDataShow"
    >
      <img src="@/assets/image/kong_yhj.png" alt="">
      <p>您还没有优惠券~</p>
    </div> -->
    <el-dialog title="赠送优惠券" :visible.sync="dialogTableVisible">
      <div>
        <img
          class="iv_icon_small"
          style="width: 18px;height: 18px"
          src="../../../assets/image/qr.png"
        >
        <span class="dialog_span">微信扫码分享</span>
        <img
          class="iv_qr_code"
          :src="ivQr"
        >
        <p class="dialog_tip">赠送好友之后，您就不能使用该优惠劵了哦~</p>
      </div>
    </el-dialog>

    <el-pagination
      v-show="count>10"
      class="page"
      background
      :total="count"
      layout="prev, pager, next"
      :page-size="queryMsg.limit"
      @current-change="handleCurrentChange"
    >
    </el-pagination>

  </div>
</template>

<script>
import { couponUnused } from '@/api/coupon.js'
import headers from '@/components/topTitle/index.vue'
import noData from '@/components/noDataBox/index.vue'
export default {
  components: { headers, noData },
  data() {
    return {
      headName: '未使用',
      msg: '您还没有优惠券~',
      img: require('@/assets/image/kong_yhj.png'),
      ivQr: require('../../../assets/image/avatar.png'),
      name: '',
      isShowEmpty: false,
      dialogTableVisible: false,
      resultLocalData: {
        errNo: 0,
        msg: 'success',
        result: [

        ]
      },
      queryMsg: {
        page: 1,
        limit: 10
      },
      count: 10
    }
  },

  computed: {},

  created() {
    this.getUnusedList()
  },

  mounted() {

  },

  methods: {
    getUnusedList() {
      couponUnused(this.queryMsg).then((res) => {
        if (res.errNo === 0) {
          this.count = res.result.count
          this.resultLocalData.result = res.result.list
          if (this.resultLocalData.result.length === 0) {
            this.isShowEmpty = true
          } else {
            this.resultLocalData.result.forEach(item => {
              switch (item.type_id) {
                case 1:
                  item.type_name = '老用户'
                  item.type_show = true
                  break
                case 2:
                  item.type_name = '社群好友'
                  item.type_show = true
                  break
                case 10:
                  item.type_show = false
                  break
                case 11:
                  item.type_name = 'V会员'
                  item.type_show = true
                  break
                case 12:
                  item.type_name = 'E会员'
                  item.type_show = true
                  break
                default:
                  item.type_show = false
                  break
              }
            })
          }
          console.log('====>' + this.resultLocalData.result.length)
        }
      })
    },
    // 分页
    handleCurrentChange(val) {
      this.queryMsg.page = val
      this.getUnusedList()
    },
    goToDetails(ivQr) {
      this.ivQr = ivQr
      this.dialogTableVisible = true
    },
    // 去使用
    usercoupon(item) {
      console.log(item, 'iiiiiii')
      // window.open(item.url_use)
      if (item.is_tk_lesson == 1) {
        const url = `https://www.zk468.com/kb_timetable?lesson_id=${item.lesson_id}&is_tk_lesson=1`
        window.open(url)
        return
      }

      // 判断是筑龙还是注考帮
      if (window.location.href.includes('zk468')) {
        if (item.lesson_id > 0) {
          window.open('/kb_timeTable?lesson_id=' + item.lesson_id)
        } else {
          window.open('/')
        }
      } else {
        const use_url = item.url_use || ''
        window.open(use_url)
      }
    }
  }
}

</script>
